@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-rajdhani: 'Rajdhani', sans-serif;
}

@layer base { * { @apply box-border; } 
body { @apply font-rajdhani; } 
h1, h2, h3, h4, h5, h6 { @apply font-bold uppercase; } 
h1{ @apply text-5xl md:text-[80px] leading-none; } 
h2{ @apply text-[40px]; } 
h3{ @apply text-[22px]; } 
h4{ @apply text-[18px]; } 
a { @apply font-bold uppercase leading-none hover:text-accent transition-all duration-200; } 
.btn { @apply flex justify-center items-center h-[50px] px-8 text-white font-semibold uppercase rounded-sm; }
.btn-centeredproduct { @apply flex justify-center items-center h-[25px] w-[25] px-4 text-white font-semibold uppercase rounded-sm; }  
.btn-icon-centeredproduct { @apply flex justify-center items-center w-[50px] h-[25px] text-sm text-white rounded-sm; } 
.btn-icon { @apply flex justify-center items-center w-[50px] h-[50px] text-2xl text-white rounded-sm; } 
.btn-accent { @apply bg-accent hover:bg-accent-hover transition-all rounded-sm; } 
.btn-primary { @apply bg-primary hover:bg-primary-hover transition-all rounded-sm; } 
.btn-white { @apply bg-white hover:bg-white/90 text-primary transition-all rounded-sm; } }
.popular-tracker-slider .swiper-wrapper {@apply !h-[500px];}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet{@apply bg-accent;}